#about {
  padding: 100px 0;
  color: black;
}

#about .container {
  display: grid;
  width: 850px;
  grid-template-rows: auto auto;
  grid-template-columns: 350px 550px;
  grid-template-areas: "h h" "i t";
}

#about .container #headline {
  grid-area: h;
  justify-self: center;
}

#about .container #text {
  grid-area: t;
}

#about .container #image {
  grid-area: i;
}

#about .container #image #about-img {
  margin-top: 5px;
  width: 300px;
  height: auto;
}

#philosophy {
  padding: 100px 0;
  width: 100%;
}

#philosophy .container {
  display: grid;
  width: 850px;
  grid-template-rows: auto auto;
  grid-template-columns: 350px 550px;
  grid-template-areas: "h h" "i t";
}

#philosophy .container #headline {
  grid-area: h;
  justify-self: center;
}

#philosophy .container #text {
  grid-area: t;
}

#philosophy .container #image {
  grid-area: i;
}

#philosophy .container #philosophy-img {
  margin-top: 5px;
  width: 300px;
  height: auto;
}

@media (max-width: 992px) {
  .home h2, h4, u, p {
    text-align: center;
  }

  #about {
    padding: 50px 0;
  }  

  #about .container {
    display: grid;
    width: 100%;
    grid-template-rows: auto auto auto;
    grid-template-columns: 100%;
    grid-template-areas: "h" "i" "t";
  }

  #about .container #about-img {
    margin: auto;
    margin-top: 0px;
    margin-bottom: 30px;
    width: 70vw;
    max-width: 250px;
    height: auto;
    float: none;
  }

  #philosophy {
    padding: 50px 0;
  }

  #philosophy .container {
    display: grid;
    width: 100%;
    grid-template-rows: auto auto auto;
    grid-template-columns: 100%;
    grid-template-areas: "h" "i" "t";
  }
}
