#portfolio {
  padding: 100px 0;
  width: 100%;
}

#portfolio .container {
  display: grid;
  max-width: 850px;
}

#portfolio .container #text-section {
  padding-left: 5px;
}

#portfolio .container #text-section ul {
  padding-left: 20px;
}