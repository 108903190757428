#dsgvo {
    all: initial;
  }
  
  #dsgvo #text {
    margin-top: 75px;
  }
  
  #dsgvo h1 {
    font-weight: 800;
    font-size: 36px;
    text-align: left;
  }
  
  #dsgvo h2 {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 28px;
    text-align: left;
  }
  
  #dsgvo h3 {
    font-weight: 400;
    font-size: 24px;
    text-align: left;
  }
  
  #dsgvo p {
    text-align: left;
  }
  
  @media (max-width: 768px) {
    #dsgvo h1 {
      font-weight: 600;
      font-size: 22px;
    }
    
    #dsgvo h2 {
      font-weight: 500;
      font-size: 18px;
    }
    
    #dsgvo h3 {
      font-weight: 400;
      font-size: 16px;
    }
  }
  