.contact {
  width: 100%;
  background-color: var(--primary-color-1);
}

.contact #footer {
  width: 100%;
}

.contact .container {
  display: grid;
  width: 1100px;
  grid-template-columns: 700px 400px;
  margin: auto;
  padding: 20px;
}

.contact .container a {
  font-weight: 400;
}
.contact .container a:hover,
.contact .container a:focus {
  text-decoration: none;
  color: var(--highlightcolor);
}

.contact .container .contact-form {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.contact .container .contact-form .invalid-input {
  border: none;
  outline: 2px solid #ED4337;
  border-radius: 5px;
}

.contact .container .contact-form .section-title {
  height: auto;
}

.contact .container .contact-form .contact-form-input {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 40px 1fr 50px;
  grid-template-areas: "n e" "msg msg" "btn btn";
  row-gap: 10px;
  column-gap: 10px;
}

.contact .container .contact-form .contact-form-input #name {
  grid-area: n;
}

.contact .container .contact-form .contact-form-input #mail {
  grid-area: e;
}

.contact .container .contact-form .contact-form-input #msg {
  grid-area: msg;
}

.contact .container .contact-form .contact-form-input #btn {
  grid-area: btn;
  width: 250px;
  background: transparent;
  border: 2px solid var(--highlight-color-1);
  border-radius: 25px;
  color: var(--highlight-color-1);
  cursor: pointer;
}

.contact .container .contact-form .contact-form-input #btn:hover:enabled {
  background: var(--highlight-color-1);
  color: var(--highlight-foreground-1);
}

.contact .container .contact-form .contact-form-input #btn:disabled {
  cursor: auto;
}

.contact .container .contact-form .section-title .section-headline {
  color: var(--highlight-color-1);
}

.contact .container .contact-form .section-title .section-headline::after {
  left: 30px;
  background: var(--highlight-color-1);
}

.contact .container .contact-links {
  display: grid;
  justify-self: center;
  grid-template-rows: repeat(4, auto);
  row-gap: 20px;
  font-size: 15px;
}

.contact .container .contact-links .contact-headline {
  font-size: 20px;
  margin-top: 80px;
  padding-bottom: 5px;
}

.contact .container .contact-links .contact-part {
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 10px;
}

.contact .container .contact-links .contact-part .contact-part-header {
  display: grid;
  grid-template-columns: 30px auto;
  align-content: center;
}

.contact .container .contact-links .contact-part .contact-part-header .text {
  line-height: 20px;
}

.contact .container .contact-links .contact-part .contact-part-header .icon {
  height: 20px;
  /* filter: invert(36%) sepia(14%) saturate(1798%) hue-rotate(314deg) brightness(96%) contrast(98%); */
  /* filter: invert(33%) sepia(40%) saturate(775%) hue-rotate(125deg) brightness(99%) contrast(95%); */
  /* filter: invert(20%) sepia(7%) saturate(4301%) hue-rotate(125deg) brightness(92%) contrast(93%); */
}


@media (max-width: 1100px) {
  .contact .container {
    width: auto;
    grid-template-columns: 65% 35%;
  }
}

@media (max-width: 992px) {
  .contact .container {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
  }

  .contact .container .contact-form .contact-form-input {
    grid-template-columns: 1fr;
    grid-template-rows: 40px 40px 1fr 50px;
    grid-template-areas: "n" "e" "msg" "btn";
    }
}