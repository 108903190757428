.header {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-areas: "in" "nav";
}

.intro {
  grid-area: in;
  display: grid;
  width: 100vw;
  height: calc(50vh - 55px);
  background-color: var(--primary-color-1);
}

.intro #img-container {
  justify-self: center;
}

.intro #img {
  height: 45vh;
}

@media (max-aspect-ratio: 5/8) {
  .intro {
    height: calc(45vh - 55px);
  }

  .intro #img {
    max-width: 100%;
    max-height: 40vh;
  }

  #start-text {
    min-height: calc(55vh);
  }
}

.navbar {
  grid-area: nav;
  height: auto;
  padding-right: 10px;
  border-color: rgba(231, 231, 231, 0);
	box-shadow: 0 -4px 5px rgba(0,0,0,0.15)
}

.navbar .nav-link {
  padding-left: 10px;
  margin-left: 20px;
}

.navbar .navbar-brand {
  padding-left: 10px;
}

.navbar .navbar-toggler {
  border: none;
}

.navbar .navbar-toggler:focus {
  box-shadow: none;
}

#start-text {
  width: 100%;
  min-height: calc(50vh);
  display: grid;
}

#start-text > .container {
  display: grid;
  width: 850px;
  justify-items: center;
  align-items: center;
}

#start-text > .container > .hero-grouping {
  display: grid;
  height: max-content;
}

#start-text > .container > .hero-grouping > #hero {
  font-size: 54px;
  font-weight: 550;
  margin-bottom: 20px;
  color: var(--highlight-color-1);
  text-align: center;
  text-transform: uppercase;
}

#start-text > .container > .hero-grouping > ul{
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#start-text > .container > .hero-grouping > ul > li{
  font-size: 24px;
  font-weight: 300px;
}

@media (max-width: 992px) {
  #start-text > .container {
    width: 100%;
    align-items: center;
  }

  #start-text > .container > .hero-grouping > #hero {
    font-size: 36px;
    margin-bottom: 20px;
  }

  #start-text > .container > .hero-grouping > ul > li{
    font-size: 16px;
    font-weight: 200px;
  }
}

@media (max-width: 650px) {
  .header {
    grid-template-areas: "nav" "in";
  }
}

@media (min-width: 650px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
}

@media (max-width: 434px) {
  #start-text > .container > .hero-grouping > ul{
    padding-left: 33px;
  }
}