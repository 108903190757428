#faq {
  padding: 100px 0;
  width: 100%;
}

#faq .container {
  display: grid;
  max-width: 850px;
}

#faq .container ol {
  padding-left: 20px;
}


@media (max-width: 1100px) {
  .contact .container {
    width: auto;
    grid-template-columns: 65% 35%;
  }
}