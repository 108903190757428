:root {
  --ui-border-radius: 8px;
  --ui-border-radius-form: 4px;
  --ui-border-radius-interaction: 1.2rem;
  --contrastBackground: #BF8D30;
  --highlightOnContrast: linear-gradient(to right, #747171 0%, #c0c0c0 100%);
  --highlightcolor: #070505;

  --primary-color-1: #efefef;
  --primary-color-2: linear-gradient(180deg, rgba(239,239,239,1) 0%, rgba(166,156,148,1) 50%, rgba(239,239,239,1) 100%);
  --primary-foreground: black;
  --highlight-color-1: black;
  --highlight-foreground-1: #efefef;
  /* --highlight-color-1: #9B4848; */
  /* --highlight-color-1: #127369; */
  /* --highlight-color-1: #10403B; */
  --contrast-color-1: #747474;
  --contrast-foreground: #efefef;
}

.navbar {
  background-color: var(--primary-color-1);
  --bs-navbar-color: var(--primary-foreground);
  --bs-navbar-brand-color: var(--primary-foreground);
}

.nav-tabs {
  --bs-nav-tabs-link-hover-border-color: transparent;
}

body,
html {
  font-family: Garamond,Baskerville,Baskerville Old Face,Hoefler Text,Times New Roman,serif; 
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 1.05rem;
  font-style: normal;
  background-color: var(--primary-color-1);
  color: var(--primary-foreground);

  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  width: 100% !important;
  height: 100% !important;
  overflow-wrap: break-word;
}

h2 {
  text-transform: uppercase;
  margin: 0 0 20px;
  font-weight: 500;
  font-size: 36px;
  line-height: 60px;
}
h3 {
  font-size: 1.05rem;
  font-weight: normal;
  display: inline !important;
}
h4 {
  font-size: 18px;
  font-weight: 600;
}
h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}

ul,
ol {
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}
hr {
  height: 4px;
  width: calc(100% - 20px);
  text-align: center;
  position: relative;
  background: var(--contrast-color-1);
  margin-bottom: 20px;
  border: 0;
}

.btn-custom {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--highlightcolor);
  background-image: var(--contrastBackground);
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff;
  background-image: none;
  background-color: var(--highlightcolor);
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.section-title {
  margin-bottom: 20px;
}
.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.section-headline {
  text-transform: uppercase;
  margin: 0 0 20px;
  font-weight: 800;
  font-size: 36px;
}

@media (max-width: 992px) {
  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
  }
}
