#partner {
  padding: 80px 0px;
}

#partner #partner-cards {
  display: grid;
  grid-auto-rows: auto;
}

#partner #partner-cards .card {
  display: grid;
  height: max-content;
  padding-top: 20px;
  background-color: transparent;
  grid-template-rows: auto minmax(150px, auto);
  grid-template-columns: 200px 1fr;
  grid-template-areas: "i h" "i t";
  border: none;
}

#partner #partner-cards .card .link {
  display: grid;
  grid-area: i;
}

#partner #partner-cards .card .link .link-img {
  height: auto;
  width: 200px;
  justify-self: center;
  align-self: top;
}

#partner #partner-cards .card h2 {
  grid-area: h;
  text-align: left;
  margin-left: 25px;
}

#partner #partner-cards .card p {
  grid-area: t;
  text-align: left;
  margin-left: 25px;
}

@media (max-width: 1200px) {
  #partner #partner-cards .card h2 {
    grid-area: h;
    font-size: 26px;
  }

  #partner #partner-cards .card p {
    grid-area: t;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  #partner #partner-cards .card {
    grid-template-columns: 70px 1fr;
  }

  #partner #partner-cards .card .link .link-img {
    height: auto;
    width: 70px;
    justify-self: center;
    align-self: top;
  }

  #partner #partner-cards .card h2 {
    grid-area: h;
    font-size: 20px;
  }

  #partner #partner-cards .card p {
    grid-area: t;
    font-size: 14px;
  }
}
